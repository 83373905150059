import React, { useState } from "react";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import axios from "axios";

const Form_Contact = ({ referrer }) => {
  const [validated, setValidated] = useState(false);
  const [loading, toggleLoading] = useState(false);
  const [submitted, toggleSubmitted] = useState(false);
  const [formData, updateFormData] = useState({});
  const handleChange = (e) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    toggleLoading(true);
    event.preventDefault();
    console.log("SUBMIT", formData);
    if (form.checkValidity() === false) {
      event.stopPropagation();
      toggleLoading(false);
    } else {
      axios
        .post(`${process.env.SERVER_URL}/api/contact`, {
          values: formData,
          referrer,
        })
        .then((res) => {
          toggleSubmitted(true);
          toggleLoading(false);
        })
        .catch((err) => console.log(err));
    }
    setValidated(true);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit} className="form">
      <Form.Group controlId="validationEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control required type="email" name="EMAIL" onChange={handleChange} />
        <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
      </Form.Group>
      <Form.Group controlId="validationEmail">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control type="tel" name="PHONE" onChange={handleChange} />
      </Form.Group>
      <Form.Group controlId="validationFirstName">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" name="NAME" onChange={handleChange} />
        {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
      </Form.Group>
      <Form.Group controlId="validationFirstName">
        <Form.Label>Company Name</Form.Label>
        <Form.Control type="text" name="COMPANYNAME" onChange={handleChange} />
        {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
      </Form.Group>

      <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
        <input type="text" name="bInput" tabindex="-1" onChange={handleChange} />
      </div>

      {!submitted ? (
        <Button type="submit">
          {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Submit"}
        </Button>
      ) : (
        <Alert variant="success">Thank you for your interest. We will be in touch with you soon.</Alert>
      )}
    </Form>
  );
};

export default Form_Contact;
